import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./styles/index.scss";
import { isMobile } from "./utils/index";

Vue.config.productionTip = false;

(function () {
  function flex() {
    try {
      const htmlDom = document.documentElement;
      const width =
        (window.innerWidth || htmlDom.clientWidth) / window.devicePixelRatio;
      console.log("width", width);
      const pcWidth = 1024 / 2;
      if (width < pcWidth) {
        const baseSize = 16;
        const scale = (window.innerWidth || htmlDom.clientWidth) / 1280;
        // 设置页面根节点字体大小, 字体大小最小为12
        let fontSize =
          baseSize * Math.min(scale, 2) > 12
            ? baseSize * Math.min(scale, 2)
            : 12;
        // Mobile
        htmlDom.style.fontSize = isMobile()
          ? 100 / (375 / 16) + "vw"
          : fontSize + "px";
      } else {
        // PC
        htmlDom.style.fontSize = "16px";
      }
    } catch (e) {
      console.error(e);
    }
  }

  flex();
  window.addEventListener("resize", flex);
})();

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
