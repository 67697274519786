import Vue from "vue";
import VueRouter from "vue-router";
import { isMobile } from "../utils/index";

Vue.use(VueRouter);

//pc端的路由
export const pcRoutes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pc/index.vue"),
  },
];

//移动端设备路由
export const mobileRoutes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mobile/index.vue"),
  },
];

const routes = isMobile() ? mobileRoutes : pcRoutes;

const createRouter = () =>
  new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    mode: "history",
    routes: routes,
  });

const router = createRouter();

// // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
// export function resetRouter() {
//   const newRouter = createRouter();
//   router.matcher = newRouter.matcher; // reset router
// }

export default router;
